<template>
  <div class="pay-result">
    <template v-if="type">
      <div class="resultImg" :style="{ backgroundImage: `url(${payInfo.imageUrl})` }"/>
      <div class="resultWords">{{ payInfo?.description }}</div>
      <div v-if="type === 'success'" @click="handleClickFn(true)" class="resultBtn successBtn">{{
          payInfo?.btnWords
        }}
      </div>

      <div v-else class="resultBtn errorBtn" @click="handleClickFn(false)">{{ payInfo?.btnWords }}</div>
    </template>
    <template v-else>
      <div class="loading">
        <van-loading type="spinner" size="64px" vertical>加载中...</van-loading>
      </div>
    </template>
  </div>
</template>

<script>
import {driveOrderPayResult} from "@/api/order";

const PAY_TYPE = {
  success: {
    imageUrl: require('@/assets/success.png'),
    description: '订单支付成功～',
    btnWords: '好的，我知道了'
  },
  fail: {
    imageUrl: require('@/assets/error.png'),
    description: '订单支付失败～',
    btnWords: '我知道了'
  }
}
export default {
  data() {
    return {
      type: '', // 查询成功还是失败
      payInfo: {},
      shortNo: null
    }
  },
  computed: {},
  created() {
    const orderNo = this.$route.query.out_trade_no;
    if (orderNo) {
      this.queryPayResult(orderNo);
    } else {
      this.type = 'fail'
      this.payInfo = PAY_TYPE['fail'];
    }
  },

  methods: {
    // 查询支付结果
    queryPayResult(orderNo) {
      driveOrderPayResult(orderNo)
          .then(res => {
            if (res.data === true) {
              this.type = 'success';
              this.payInfo = PAY_TYPE['success'];
            } else {
              this.type = 'fail'
              this.payInfo = PAY_TYPE['fail']
            }
          })
          .finally(() => {
            this.loading = false
          })
    },
    handleClickFn(isSuccess) {
      window.close();
    }
  }
}
</script>

<style lang="scss">
.pay-result {
  width: 100%;
  height: 100vh;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  .loading {
    width: 100%;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .resultImg {
    width: 360px;
    height: 360px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 84px;
  }

  .resultWords {
    width: auto;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #333333;
    line-height: 22px;
    text-align: right;
    font-style: normal;
    margin-top: 20px;
  }

  .resultBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  .errorBtn,
  .successBtn {
    width: 250px;
    height: 80px;
    background: #f40606;
    border-radius: 4px;

    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #ffffff;
    line-height: 28px;
    text-align: right;
    font-style: normal;
  }
}
</style>
